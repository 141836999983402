import {
  Component,
  OnInit,
  Renderer2,
  ElementRef,
  ViewChild,
  HostListener
} from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  @ViewChild('backToTopBtn', { static: true }) backToTopBtn: ElementRef;
  @ViewChild('footer', { read: ElementRef, static: true }) footer: ElementRef;
  @HostListener('window:scroll', [])
  onScroll() {
    const btn = this.backToTopBtn.nativeElement;
    const footer = this.footer.nativeElement;

    const checkBtnOffset = (): void => {
      function getRectTop(el: HTMLElement): number {
        const DOMRect = el.getBoundingClientRect();
        return DOMRect.top;
      }

      if ((getRectTop(btn) + document.body.scrollTop) + btn.offsetHeight >= (getRectTop(footer) + document.body.scrollTop) - 10) {
        this.renderer.setStyle(btn, 'position', 'absolute');
      }

      if (document.body.scrollTop + window.innerHeight < (getRectTop(footer) + document.body.scrollTop)) {
        this.renderer.setStyle(btn, 'position', 'fixed');
      }
    };

    checkBtnOffset();
  }

  constructor(
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
  }

  scrollToTop(): void {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } catch (err) {
      window.scrollTo(0, 0);
    }
  }
}
