import {
  Component,
  OnInit,
  Input,
  ElementRef,
  OnDestroy,
  HostListener,
  Renderer2,
  Inject
} from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private modalService: ModalService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.modalEl = el.nativeElement;
    this.docBody = document.body;
  }

  @Input() id: string;

  private modalEl: HTMLElement;
  private docBody: HTMLElement;

  @HostListener('click', ['$event.target'])
  closeModalOnClickOutside(target: EventTarget) {
    const { className } = target as HTMLDivElement;
    if (className === 'modal' || className.includes('modal-grid')) {
      this.closeModal();
    }
  }

  ngOnInit(): void {
    const modalComponent = this;

    if (!this.id) {
      console.error('Modal must have an id!');
      return;
    }

    this.renderer.appendChild(this.docBody, this.modalEl);
    this.modalService.add(modalComponent);
  }

  openModal(): void {
    this.renderer.setStyle(this.modalEl, 'display', 'block');
    this.renderer.addClass(this.docBody, 'modal-open');
  }

  closeModal(): void {
    this.renderer.setStyle(this.modalEl, 'display', 'none');
    this.renderer.removeClass(this.docBody, 'modal-open');
  }

  ngOnDestroy(): void {
    this.renderer.removeChild(this.docBody, this.modalEl);
    this.renderer.removeClass(this.docBody, 'modal-open');
    this.modalService.remove(this.id);
  }
}
