import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NavComponent } from './core/nav/nav.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/buyers/information',
    pathMatch: 'full',
  },
  {
    path: '',
    component: NavComponent,
    children: [
      {
        path: 'brands',
        loadChildren: () =>
          import('./brands/brands.module').then(mod => mod.BrandsModule)
      },
      {
        path: 'buyers',
        loadChildren: () =>
          import('./buyers/buyers.module').then(m => m.BuyersModule)
      },
      {
        path: 'cart',
        loadChildren: () =>
          import('./cart/cart.module').then(m => m.CartModule)
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
