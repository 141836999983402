import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-dummy-checkbox-round',
  templateUrl: './dummy-checkbox-round.component.html',
  styleUrls: ['./dummy-checkbox-round.component.scss']
})
export class DummyCheckBoxRoundComponent implements OnInit {
  @Input() checked: boolean;

  constructor() {}

  ngOnInit() {
  }

  onClick(): void {
    this.checked = !this.checked;
  }
}
