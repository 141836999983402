import {
  Component,
  OnInit,
  Input,
} from '@angular/core';
import {
  ControlContainer,
  FormGroup,
} from '@angular/forms';
import { Checkbox } from './checkbox/checkbox.component';

@Component({
  selector: 'app-checkboxes',
  templateUrl: './checkboxes.component.html',
  styleUrls: ['./checkboxes.component.scss'],
})
export class CheckboxesComponent implements OnInit {
  @Input() checkBoxes: Checkbox[];
  @Input() redTheme: boolean;
  @Input() disabled: boolean;
  @Input() textMode: boolean;

  checkboxForm: FormGroup;

  constructor(
    public controlContainer: ControlContainer,
  ) {}

  ngOnInit() {
    this.checkboxForm = this.controlContainer.control as FormGroup;
  }

}
