import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal/modal.component';
import { DummyCheckBoxRoundComponent } from './dummy-checkbox-round/dummy-checkbox-round.component';
import { RadioBtnsComponent } from './radio-btns/radio-btns.component';
import { CheckboxesComponent } from './checkboxes/checkboxes.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxComponent } from './checkboxes/checkbox/checkbox.component';
import { DummyCloseBtnComponent } from './dummy-close-btn/dummy-close-btn.component';

@NgModule({
  declarations: [
    ModalComponent,
    DummyCheckBoxRoundComponent,
    RadioBtnsComponent,
    CheckboxesComponent,
    CheckboxComponent,
    DummyCloseBtnComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [
    ModalComponent,
    DummyCheckBoxRoundComponent,
    DummyCloseBtnComponent,
    RadioBtnsComponent,
    CheckboxesComponent
  ]
})
export class SharedModule {}
