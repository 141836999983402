import {
  Component,
  OnInit,
  Input,
  forwardRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';

export interface Checkbox {
  label?: string;
  formControlName?: string;
}

const CHECKBOX_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => CheckboxComponent),
  multi: true
};

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CHECKBOX_CONTROL_ACCESSOR],
})
export class CheckboxComponent implements OnInit {
  @Input() checkBox: Checkbox;
  @Input() redTheme: boolean;
  @Input() disabled: boolean;
  @Input() textMode: boolean;

  // tslint:disable-next-line:variable-name
  _value: any;

  get value(): any {
    return this._value;
  }

  @Input('value')
  set value(value: any) {
    this._value = value;
  }

  private propagateChange: (change?: any) => void;
  private onTouch: (change?: any) => void;

  constructor(
  ) { }

  ngOnInit() {
  }

  // called when the form is initialized with the form model's initial value
  writeValue(value: any) {
    if (value != null) {
      this.value = value;
    }
  }

  // informs the parent component of changes
  registerOnChange(fn: () => any): void {
    this.propagateChange = fn;
  }

  // executed when the control is touched
  registerOnTouched(fn: () => any): void {
    this.onTouch = fn;
  }

  onClick(value: any): void {
    this.propagateChange(!value);
    this.onTouch();
  }

}
