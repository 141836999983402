import { Injectable } from '@angular/core';
import { ModalComponent } from '../shared/modal/modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modals: ModalComponent[] = [];

  constructor() {}

  add(modal: any): void {
    this.modals.push(modal);
  }

  remove(id: string): void {
    this.modals = this.modals.filter(m => m.id !== id);
  }

  open(id: string): void {
    const modal: any = this.modals.filter(m => m.id === id)[0];
    modal.openModal();
  }

  close(id: string): void {
    const modal: any = this.modals.filter(m => m.id === id)[0];
    modal.closeModal();
  }
}
